import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgHeaderImage from '../images/top_treatments.jpg'

const IndexPage = () => {

  return (
    <Layout pageTitle="Treatments & Cosmetic" headerImage={imgHeaderImage}>
      <SEO title="Treatments & Cosmetic"/>
      <div>
        <div className="two-col-2 fat">
        </div>
      </div>
      <div className="new-row">
        <div className="two-col-1 slim">
          <h2 className="section-title">Treatments</h2>
          <p className="general-text">At Pipers we develop a personal relationship with each of our patients. And we make sure that all of our patients have access to the most advanced dental prevention techniques and exceptionally high standards of treatment. Patients benefit from the following services:</p>
          <ul>
            <li><a className="lightbox" href="/lightbox/whitefillings">White fillings</a></li>
            <li><a className="lightbox" href="/lightbox/silverfillings">Silver fillings</a></li>
            <li><a className="lightbox" href="/lightbox/inlaysandonlays">Inlays and onlays</a></li>
            <li><a className="lightbox" href="/lightbox/crowns">Crowns</a></li>
            <li><a className="lightbox" href="/lightbox/fixedbridges">Bridges</a></li>
            <li><a className="lightbox" href="/lightbox/removabledentures">Dentures</a></li>
            <li><a className="lightbox" href="/lightbox/rootcanaltreatment">Root canal treatment</a></li>
            <li><a className="lightbox" href="/lightbox/jawproblems">Tempromandibular joint problems</a></li>
            <li><a className="lightbox" href="/lightbox/apicectomy">Apicectomy</a></li>
            <li><a className="lightbox" href="/lightbox/extraction">Extractions</a></li>
            <li><a className="lightbox" href="/lightbox/dentalimplants">Implants</a></li>
            <li>Orthodontics (adult teeth straightening)</li>
          </ul>
          <p className="general-text">For further information, please speak to a member of our team who will be happy to help.</p>
        </div>
        <div className="two-col-2 fat">
          <header className="with-subtitle">
            <h2 className="section-title">Cosmetic work</h2>
            <h4>to help you get the best natural looking and healthy smile</h4>
          </header>
          <p className="general-text">Our dentists will work with you to create a personal treatment plan to improve any aspect of your dental appearance that may concern you.</p>
          The treatments available include:
          <ul>
            <li><a className="lightbox" href="/lightbox/toothbleaching">Tooth whitening</a>, to brighten your smile</li>
            <li><a className="lightbox" href="/lightbox/whitefillings">Natural-coloured fillings</a>, to replace <a className="lightbox" href="/lightbox/silverfillings">silver fillings</a></li>
            <li>Ceramic technology, to replace old <a className="lightbox" href="/lightbox/crowns">crowns</a> and <a className="lightbox" href="/lightbox/fixedbridges">bridges</a></li>
            <li><a className="lightbox" href="/lightbox/veneers">Veneers</a>, to disguise chipped, crooked or unevenly worn teeth.</li>
          </ul>
          <br/>
          <h3 className="info-title">Tooth whitening</h3>
          <p className="general-text">The colour of teeth varies from person to person. Teeth can get stained but there are several ways to make them whiter. Lightening them by just a few shades can make a big difference to your smile and give you more confidence. To find out more about tooth whitening, <a href="/info/toothwhitening" className="standard-link">click here</a>.</p>
          <br/>
          <h3 className="info-title">Veneers</h3>
          <p className="general-text"><a className="lightbox" href="/lightbox/veneers">Veneers</a> can be used to improve the colour, shape and position of your teeth. They can instantly improve your smile by hiding discolouration, closing small gaps and repairing chips and cracks. A veneer is a thin slice of porcelain, or a tooth-coloured composite, made to be bonded and fitted precisely over the front surface of a tooth.</p>
          <p className="general-text">Very little preparation of the tooth is needed. Your dentist will match the shade of your natural teeth and take an impression of the tooth. This will usually be sent to a laboratory where a dental technician will create your porcelain or composite veneer. Veneers can make teeth look natural and healthy, and should last for many years.</p>
        </div>
      </div>
    </Layout>
  )
}



export default IndexPage
